.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #ccc;
}

.contact-form-container textarea {
  resize: vertical;
}

.contact-form-container button {
  width: 30%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  background-color: #FF0078;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form-container button:hover {
  filter:brightness(1.2);
}

.contact-us-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  min-height: 100vh;
  background-color: #f9f9f9;
}

/* Left section - animation */
.contact-us-left {
  width: 70%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

/* Title Styling */
.contact-us-title {
  padding-top: 20px;
  font-size: 60px;
  font-weight: bold;
  color: #333;
  text-align: center;
  font-family: 'LuckiestGuy', sans-serif;
}

/* Right section - form */
.contact-us-right {
  width: 70%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.contact-form-container {
  width: 100%;
  max-width: 500px;
  padding: 10px;
}

/* Mobile View - Animation on Top */
@media (max-width: 768px) {
  .contact-us-page {
    flex-direction: column;  /* Stack items vertically */
    align-items: center;
  }

  .contact-us-left {
    width: 100%;
    margin-top: 50px;  /* Adjust margin for spacing */
  }

  .contact-us-right {
    width: 100%;
    margin-top: 30px;
  }

  .contact-form-container button {
    width: 60%;  /* Make button larger for mobile */
  }

  .contact-us-title {
    font-size: 45px;  /* Adjust title size for smaller screens */
  }
}