/* Footer */
.footer {
  background-color: #000; /* Set background color to black */
  color: #fff; /* Set text color to white for contrast */
  padding: 10px 0; /* Reduce padding for a more compact layout */
  width: 100%; /* Ensure full width */
  position: relative; /* Allow for positioning of elements */
  text-align: center; /* Center-align text content */
}

/* Footer Top Section */
.footer-top {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align items */
  gap: 5px; /* Reduce gap between elements */
  margin-bottom: 10px; /* Reduce space between top and bottom sections */
}

/* Footer Title */
.footer-title {
  font-size: 18px; /* Adjust title size */
  margin: 0; /* Remove extra spacing */
}

/* Footer Logo */
.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Footer Social Section */
.footer-social {
  display: flex;
  flex-direction: column; /* Stack text and icons vertically */
  align-items: center;
  gap: 3px; /* Reduce space between items */
}

/* Social Span */
.footer-social span {
  font-size: 16px; /* Adjust font size */
  color: #fff; /* Ensure text is white */
  margin-bottom: 5px; /* Add slight margin below the text */
}

/* Instagram and TikTok Icons */
.instagram-icon,
.tiktok-icon {
  width: 24px;
  height: 24px;
  margin: 2px; /* Reduce margin between icons */
  transition: color 0.3s ease;
}

/* Hover effect for social icons */
.instagram-icon:hover {
  color: #c13584; /* Change Instagram icon color on hover */
}

.tiktok-icon:hover {
  color: #ffffff; /* Change TikTok icon color on hover */
}

/* Footer Bottom Section */
.footer-bottom {
  display: flex;
  justify-content: space-between; /* Space left and right sections */
  flex-wrap: wrap; /* Wrap items on smaller screens */
  gap: 2px; /* Reduce spacing between sections */
  margin-top: 5px; /* Add slight spacing from the top */
}

/* Footer Left and Right */
.footer-left,
.footer-right {
  font-size: 14px; /* Adjust font size */
  color: #fff; /* Ensure text is white */
}
/* Container for email subscription */
.subscribe-email {
  margin-bottom: 20px;
  text-align: center;
}

.subscribe-email-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Email subscription container */
.email-subscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Wrapper for input and button */
.email-input-wrapper {
  position: relative;
  width: 300px;
}

/* Input box styling */
.email-input {
  width: 75%;
  padding: 10px 50px 10px 15px; /* Add right padding for the button */
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 50px;
  outline: none;
  transition: border-color 0.3s ease;
}

.email-input:focus {
  border-color: #4e18fd;
}

/* Button inside input box */
.subscribe-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: #FF0078;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #3713b2;
}
/* Responsive Styling */
@media (max-width: 768px) {
  .footer{
    width:100%;
  }
  .footer-bottom {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align items */
    text-align: center; /* Center-align text */
  }

  .footer-left,
  .footer-right {
    font-size: 12px; /* Adjust font size for mobile */
  }
}
