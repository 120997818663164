.team-card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
    padding: 20px;
    background: #fff; /* Background color for the card */
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
    max-width: 450px;
    margin: 20px; /* Spacing between cards */
    margin-bottom:100px;
}

.team-image {
    width: 120px; /* Set the width of the image */
    height: 120px; /* Set the height of the image */
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Maintain aspect ratio */
    margin-bottom: 15px; /* Spacing below the image */
}

.team-name {
    font-size: 20px; /* Name font size */
    margin: 10px 0; /* Spacing above and below */
}

.team-position {
    font-size: 16px; /* Position font size */
    color: #777; /* Gray color for position */
    margin: 5px 0; /* Spacing above and below */
}

.team-description {
    font-size: 16px; /* Description font size */
    color: #555; /* Darker gray for description */
    margin-bottom: 10px; /* Spacing below the description */
    word-spacing: 4px;
    font-family: 'Montserrat', sans-serif;

}

.linkedin-link {
    color: #0077b5; /* LinkedIn blue color */
    text-decoration: none; /* Remove underline */
}

.linkedin-icon {
    transition: color 0.3s; /* Smooth transition for color */
}

.linkedin-icon:hover {
    color: #005582; /* Darker blue on hover */
}