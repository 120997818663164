.about-page {
  padding: 40px;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-page-title {
  font-size: 36px;
  padding-top: 80px;
  font-weight: bold;
  color: Black;
  
  text-align: center;
  margin-bottom: 30px;
  font-family: 'LuckiestGuy', sans-serif;
}

.about-us-container {
  max-width: 1000px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
}

.about-section {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Slide from the left */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.about-section.slide-in {
  opacity: 1;
  transform: translateX(0); /* Slide to original position */
}

/* Reverse the flex direction for alternating sections */
.about-section.reverse .content-animation-container {
  flex-direction: row-reverse;
}

/* Container for content and animation */
.content-animation-container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 80%;
}

.content-container {
  width: 50%;
  
}

.content-container h2 {
  font-size: 28px;
  font-weight: bold;
  color: #FF0078;
  margin-bottom: 15px;
}


.content-container p {
  font-size: 18px;
  color: #210606;
  margin-bottom: 15px;
  text-align: justify;
}

.values-list {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;
}

.values-list li {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
  text-align: justify;
}

.animation-container {
  width: 50%;
}

.team-title{
  font-size: 32px;
  font-weight: bold;
  color: #FF0078;
  margin-bottom: 15px;
  text-align: center;
  margin-bottom: 30px;

    width: 100%;  /* Ensures the title is on top of both cards */

  
}
.about-team{
  display: flex;

  
  transform: translateX(-50px); 
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.about-team.slide-in {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .about-team {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .content-animation-container {
    flex-direction: column;
    align-items: center;
  }

  .content-container,
  .animation-container {
    width: 100%;
  }
}