.blog-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .blog-card {
    width: calc(50% - 40px); /* Two cards per row */
    max-width: 500px;
    background: linear-gradient(135deg, #ffffff, #defffd);
    border: 1px solid #000;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .blog-card:hover {
    transform: scale(1.03);
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding:5px 5px 5px 5px;
  }
  
  .blog-text {
    padding: 15px;
  }
  
  .blog-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .blog-content {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .read-more-btn {
    background-color: #FF0078;
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
  }
  
  .read-more-btn:hover {
    filter: brightness(1.2);
  }
  @media (max-width: 768px) {
    .blog-card {
      width: 100%; /* Full width */
      max-width: 90%; /* Limit to 90% of screen width */
    }
  }
  
  /* Two cards per row for tablets */
  @media (max-width: 1024px) and (min-width: 769px) {
    .blog-card {
      width: calc(50% - 30px); /* Two per row with smaller gaps */
    }
  }