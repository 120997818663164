/* Global Font Face Setup */
@font-face {
  font-family: 'LuckiestGuy';
  src: url('./assets/fonts/LuckiestGuy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* General Application Styling */
.App {
  text-align: center;
}
/* Wrapper for Home Content */
.home-wrapper {
  opacity: 0; /* Initially hidden */
  transform: translateX(100%); /* Start off to the right */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Slide-in transition */
}

.home-wrapper.slide-in {
  opacity: 1; /* Fade in */
  transform: translateX(0); /* Slide to original position */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Font Family Fix */
body, .App, .home-container, .content-section, .home-quote {
  font-family: 'Montserrat', sans-serif;
}

/* Slide-In Animation for Home Page */
.home-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  opacity: 0; /* Initially hidden */
  transform: translateX(100%); /* Slide from the right */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.home-container.slide-in {
  opacity: 1;
  transform: translateX(0); /* Slide to the original position */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}