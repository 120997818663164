/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #000; /* Black background remains */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Logo Container */
.navbar-logo-container {
  display: flex;
  align-items: center;
}

/* Desktop Links */
.navbar-links {
  display: flex;
  gap: 30px;
  padding-right: 30px;
}

/* Individual Link Styling */
.navbar-link {
  font-size: 20px;
  font-weight: bold;
  text-decoration: bold;
  padding: 10px 15px;
  color: #fff;
  background:#FF0078 ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Makes gradient visible */
}

.navbar-link:hover {
  background: linear-gradient(90deg, #fff4f9, #F6EFA7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline; /* Optional underline effect on hover */
}

/* Hamburger Menu Icon (Hidden on Desktop) */
.navbar-menu-icon {
  display: none; /* Hidden by default on larger screens */
  cursor: pointer;
  margin-right: 20px; /* Add right margin for spacing */
  color: #fff;
}

/* Menu Icon Styling */
.menu-icon {
  width: 28px;
  height: 28px;
  transition: transform 0.3s ease;
  color: #fff;
}

/* Rotate Animation */
.menu-icon.rotate {
  transform: rotate(90deg);
}

/* Book an Appointment Button */
.navbar-button {
  background:  #FF0078;
  color: white;
  padding: 12px 25px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  margin-left: 15px;
  border: none; /* Remove border */
  cursor: pointer;
  
}

.navbar-button:hover {
  filter: brightness(1.2); /* Brighten on hover */
}

/* Responsive Mobile Menu */
@media (max-width: 768px) {
  .navbar-menu-icon {
    display: block; /* Show hamburger icon on mobile */
    color: white;
    margin-right: 20px; /* Keep the gap on mobile too */
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: #000; /* Black background remains */
    position: absolute;
    top: 80px; /* Add space below the navbar */
    left: 0;
    width: 100%;
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transform: translateY(-10px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  /* Show Links When Toggled */
  .navbar-links.block {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  .navbar-link {
    padding: 12px 25px;
    width: 100%;
    text-align: left;
  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
  }

  .navbar-button {
    width: 80%; /* Full width on mobile */
    margin: 10px auto;
    text-align: center;
  }
}
