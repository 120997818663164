.resources-page {
  padding: 40px;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resources-page-title {
  font-size: 36px;
  padding-top: 80px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-bottom: 30px;
  font-family: 'LuckiestGuy', sans-serif;
}

.resource-section {
  max-width: 1000px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Slide from the left */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.resource-section.slide-in {
  opacity: 1;
  transform: translateX(0); /* Slide to original position */
}

.resource-section.alternate-layout {
  flex-direction: row-reverse;
}

/* Container for content and animation */
.content-container {
  width: 80%;
}

.content-container h2 {
  font-size: 28px;
  font-weight: bold;
  color: #FF0078;
  margin-bottom: 15px;
}

.content-container p,
.content-container ul {
  font-size: 18px;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
}

.services-list {
  list-style-type: disc;
  padding-left: 20px;
}

.services-list li {
  margin-bottom: 10px;
}

.animation-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}