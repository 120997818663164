/* Container for the Home Page */
.home-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  opacity: 0; /* Initially hidden */
  transform: translateX(100%); /* Slide from the right */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.home-container.slide-in {
  opacity: 1;
  transform: translateX(0); /* Slide to the original position */
}

/* Full-Width Logo and Animation Section */
.home-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  width: 100%;
  height: 800px;
}

/* Left Section Styling */
.home-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
}

.home-logo .logo-container {
  transform: scale(2.3);
  padding-left: 100px;
}

.home-logo .logo-college {
  color: #000000 !important;
}

.home-quote {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-top: 20px;
  line-height: 1.6;
  padding-left: 160px;
}

/* Right Section Styling */
.home-right {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.home-right .lottie-animation {
  width: 350px;
  height: 350px;
}

/* Wrapper for Side-by-Side Content Sections */
.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  padding: 40px 0;
}

/* General Content Sections Styling */
.content-section {
  padding: 40px;
  background: linear-gradient(135deg, #ffffff, #fffafa);
  border-radius: 50px;
  box-shadow: 10px 5px 20px rgba(136, 81, 81, 0.1);
  border: 1px solid #f0f0f0;
  width: 45%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  opacity: 0; /* Initially hidden */
  transform: translateY(30px); /* Move down initially */
  transition: opacity 2s ease-out, transform 2s ease-out;
  margin-bottom: 50px;
}

/* Hover Effect for .content-section */
.content-section:hover {
  transform: scale(1.05); /* Scale up on hover */
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.6); /* Increase shadow */
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

/* Fade-in animation */
.content-section.visible {
  opacity: 1;
  transform: translateY(0); /* Moves to original position */
}

/* Buttons */
.home-button {
  padding: 15px 25px;
  font-size: 22px;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  align-self: center;
  background-color: #FF0078 ;
  color: #fff;
  margin-top: 20px;
  width: auto;
}

.home-button:hover {
  filter: brightness(1.2); /* Brighten on hover */
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .home-top {
    flex-direction: column; /* Stack elements vertically */
    height: auto;
    padding: 60px 20px;
  }

  .home-logo .logo-container {
    transform: scale(1.8);
    padding-left: 50px;
  }

  .home-quote {
    font-size: 22px;
    padding-left: 0;
    text-align: center;
  }

  .home-left, .home-right {
    max-width: 100%;
    padding: 0;
    align-items: center;
  }

  .home-right .lottie-animation {
    width: 250px;
    height: 250px;
  }

  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .content-section {
    width: 90%;
    margin: 20px 0;
  }

  .home-button {
    font-size: 20px;
    width: 80%;
  }
}

@media (max-width: 768px) {
  .home-top {
    flex-direction: column;
    height: auto;
    padding: 80px 10px;
  }

  .home-logo .logo-container {
    transform: scale(1.3);
    padding-left: 0;
  }

  .home-left, .home-right {
    max-width: 100%;
    padding: 0;
  }

  .home-quote {
    padding-left: 0;
    text-align: center;
    font-size: 20px;
  }

  .content-wrapper {
    flex-direction: column;
    gap: 30px;
  }

  .content-section {
    width: 100%;
    margin: 20px 0;
    font-size: 18px;
    padding: 25px;
  }

  .home-button {
    width: 90%;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .home-logo .logo-container {
    transform: scale(1);
  }

  .home-quote {
    font-size: 18px;
  }

  .home-right .lottie-animation {
    width: 200px;
    height: 200px;
  }

  .content-section {
    font-size: 16px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .home-button {
    font-size: 16px;
    padding: 12px 18px;
  }
}